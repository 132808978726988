.toggle-container {
    display: flex;
    justify-content: space-between;
    width: 48px;
    background-color: var(--deep-blue-95);
    cursor: pointer;
    border-radius: 50px;
    padding: 5px;
    box-shadow: inset 0 0 0 1px var(--deep-blue-20);
}

.disabledButton {
    box-shadow: inset 0 0 0 1px var(--deep-blue-80);
}

.toggle-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 50%;
}

.toggle-btn-on {
    background-color: var(--deep-blue-20);
}

.toggle-btn-off {
    background-color: var(--deep-blue-80);
}

.disable {
    background-color: transparent;
}