.hxp-comp-button {
  height: 48px;
  border-radius: 24px;
}

.hxp-comp-button>.button {
  background: var(--progress-gradient);
  display: flex;
  gap: 8px;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  box-shadow: var(--shadow-around-deep-blue-20);
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--deep-blue-99);
}

.hxp-comp-button>.button>.label {
  font-size: 16px;
  color: var(--deep-blue-20);
}

.hxp-comp-button>.button>.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 14px;
  fill: var(--deep-blue-20);
  stroke: var(--deep-blue-20);
}

.hxp-comp-button:hover>.button {
  background-color: var(--deep-blue-95);
  cursor: pointer;
}

.hxp-comp-button:active>.button {
  background-color: var(--deep-blue-20);
}

.hxp-comp-button:active>.button>.label {
  color: var(--deep-blue-99);
}

.hxp-comp-button:active>.button>.icon {
  stroke: var(--deep-blue-99);
}

.hxp-comp-button.variation-box-shadow {
  background-color: var(--deep-blue-20);
}

.hxp-comp-button.variation-box-shadow>.button {
  transform: translateY(-4px);
}

.hxp-comp-button.variation-box-shadow:active>.button {
  transform: translateY(0px);
}