.table {
    box-shadow: var(--shadow-around-deep-blue-95);
    width: 408px;
    border-radius: 10px;
    background-color: var(--deep-blue-99);
    border-bottom: none;
    overflow-y: scroll;
}

.table>.line-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--shadow-bottom-deep-blue-95);
    padding: 16px;
    /* border-radius: 8px; */
}

.table>.line-table>div {
    color: var(--deep-blue-20);
}

.selected-label {
    background-color: var(--deep-blue-95);
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.activeEmptyBtn {
    background-color: var(--deep-blue-20);
    color: var(--deep-blue-95);
}