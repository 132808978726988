.edit-st-container {
    height: 890px;
}

.edit-st-container>.top-div {
    width: 1540px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: var(--shadow-bottom-deep-blue-95);
    height: 65px;
    background-color: var(--deep-blue-98);
    gap: 8px;
    padding-left: 16px;
}

.edit-st-container>.button-div {
    background-color: var(--deep-blue-98);
    padding: 10px 16px 10px 16px;
    border-bottom: 1px solid #C2C2DA;
}

.edit-st-container>.content-div {
    padding-top: 16px;
    padding-left: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    height: 93%;
}

.line-container>p {
    margin: 5px;
    color: var(--deep-blue-60);
}

.smart-table-name {
    width: 150px;
    height: 48px;
    box-shadow: var(--shadow-around-deep-blue-20);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    font-weight: 500;
    border-radius: 8px;
}