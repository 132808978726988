.content-div {
    width: 100%;
    height: 100%;
    border: 1px solid var(--deep-blue-80);
    background-color: var(--deep-blue-99);
    overflow-y: scroll;
    border-radius: 8px;
}

.content-div>.table-div {
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    /* justify-content: center; */
    gap: 8px;
}


/* @media screen and (max-width : 1700px) {
    .content-div>.table-div{
        width: 1100px;
    }
}
@media screen and (max-width : 1400px) {
    .content-div>.table-div{
        width: 750px;
    }
}
@media screen and (max-width : 940px) {
    .content-div>.table-div{
        width: 540px;
    }
}
@media screen and (max-width : 600px) {
    .content-div>.table-div{
       width: 400px;
    }
} */