.drop-down {
    /* width: 256px; */
    width: 200px;
    height: 48px;
    background-color: var(--deep-blue-99);
    color: var(--deep-blue-20);
    box-shadow: var(--shadow-around-deep-blue-20);
    font-size: 16px;
    padding: 0px 16px;
    font-weight: 500;
    border-radius: 8px;
    cursor: pointer;
}

.drop-down.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}