/* width */
::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border: 4px solid var(--deep-blue-99);
  border-radius: 8px;
  background: var(--shadow-around-deep-blue-99);
  box-shadow: var(--shadow-around-deep-blue-80);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-clip: padding-box;
  border: 4px solid var(--deep-blue-99);
  background: var(--deep-blue-95);
}

::-webkit-scrollbar-thumb:active {
  background-clip: padding-box;
  border: 4px solid var(--deep-blue-99);
  background: var(--deep-blue-95);
  box-shadow: var(--shadow-around-deep-blue-20);
}

body {
  width: 100%;
  height: 100vh;
  background-color: var(--deep-blue-99);
}

.App {
  display: grid;
  height: 100%;
  grid-template-columns: 64px var(--secondary-menu-width) auto;
  grid-template-rows: 64px 80px auto;
  width: 100%;
  overflow: hidden;
}

.App>.menu-primary {
  grid-column: 1;
  grid-row: 1 / span 2;
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.App>.menu-secondary {
  height: calc(100vh - 64px);
  grid-column: 2;
  padding-right: 1px;
  box-shadow: var(--shadow-right-deep-blue-80);
  background-color: var(--deep-blue-98);
  position: relative;
}

.App>.menu-secondary>.hide-bar {
  position: absolute;
  top: 0;
  right: -4px;
  height: 100%;
  width: 9px;
  background-color: var(--deep-blue-80);
  opacity: 0;
  cursor: col-resize;
}

.App>.menu-secondary>.hide-bar:hover {
  opacity: 0.5;
}

.App>.menu-secondary.hidden {
  width: 65px;
}

.App>.top-bar {
  grid-column: 2 / span 2;
  height: 64px;
  background-color: var(--deep-blue-98);
  box-shadow: var(--shadow-bottom-deep-blue-80);
  display: flex;
  padding: 0  16px;
  align-items: center;
}

.App>.hxp-comp-action-bar {
  grid-column: 3;
  grid-row: 2;
  height: 80px;
  background-color: var(--deep-blue-98);
  box-shadow: var(--shadow-bottom-deep-blue-95);
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.App.hxp-comp-action-bar::-webkit-scrollbar {
  display: none;
}

.App>.main-content {
  display: grid;
  grid-column: 3;
  grid-row: 3;
  height: calc(100vh - 64px - 80px);
  overflow: hidden;
  width: 100%;
  padding: 16px;
  background-color: var(--deep-blue-98);
}