.button-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  position: relative;
}

.button-input>label {
  display: none;
}

.button-input>input {
  position: absolute;
  text-align: right;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}