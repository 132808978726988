.principal-menu {
    width: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: var(--shadow-right-deep-blue-80);
    background-color: var(--deep-blue-98);
    justify-content: space-between;
}
.principal-menu>.bottom-div {
    box-shadow: var(--shadow-top-deep-blue-95);
}