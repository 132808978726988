.common-keys {
    height: 100%;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.common-keys>.container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-shadow: var(--shadow-around-deep-blue-95);
    border-radius: 8px;
    background-color: var(--deep-blue-99);
}

.common-keys>.label {
    padding-left: 8px;
}

.common-keys>.container>.line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-shadow: var(--shadow-bottom-deep-blue-95);
    height: 64px;
    width: 100%;
}