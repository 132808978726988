:root {
    /* deep-blue */
    --deep-blue-20: hsl(240, 20%, 20%);
    --deep-blue-60: hsl(240, 20%, 60%);
    --deep-blue-80: hsl(240, 20%, 80%);
    --deep-blue-90: hsl(240, 20%, 90%);
    --deep-blue-95: hsl(240, 20%, 95%);
    --deep-blue-98: hsl(240, 20%, 98%);
    --deep-blue-99: hsl(240, 20%, 99%);

    /* pink */
    --pink-20: hsl(320, 100%, 20%);
    --pink-60: hsl(320, 100%, 60%);
    --pink-80: hsl(320, 100%, 80%);
    --pink-90: hsl(320, 100%, 90%);
    --pink-95: hsl(320, 100%, 95%);
    --pink-98: hsl(320, 100%, 98%);
    --pink-99: hsl(320, 100%, 99%);

    /* deep blue shadows */
    --shadow-bottom-deep-blue-80: inset 0 -1px 0 0 var(--deep-blue-80);
    --shadow-bottom-deep-blue-20: inset 0 -1px 0 0 var(--deep-blue-20);
    --shadow-bottom-deep-blue-95: inset 0 -1px 0 0 var(--deep-blue-95);

    --shadow-right-deep-blue-80: inset -1px 0 0 0 var(--deep-blue-80);
    --shadow-right-deep-blue-95: inset -1px 0 0 0 var(--deep-blue-95);

    --shadow-left-deep-blue-80: inset 1px 0 0 0 var(--deep-blue-80);
    --shadow-left-deep-blue-95: inset 1px 0 0 0 var(--deep-blue-95);

    --shadow-top-deep-blue-80: inset 0 1px 0 0 var(--deep-blue-80);
    --shadow-top-deep-blue-95: inset 0 1px 0 0 var(--deep-blue-95);

    --shadow-around-deep-blue-80: inset 0 0 0 1px var(--deep-blue-80);
    --shadow-around-deep-blue-20: inset 0 0 0 1px var(--deep-blue-20);
    --shadow-around-deep-blue-95: inset 0 0 0 1px var(--deep-blue-95);

    /* pink shadows */
    --shadow-around-pink-80: inset 0 0 0 1px var(--pink-80);
}

@media (prefers-color-scheme: dark) {
    :root {

        --dark-correction: 10%;
        /* deep-blue for dark mode */
        --deep-blue-20: hsl(240, 20%, calc(100% - 20% + var(--dark-correction)));
        --deep-blue-60: hsl(240, 20%, calc(100% - 60% + var(--dark-correction)));
        --deep-blue-80: hsl(240, 20%, calc(100% - 80% + var(--dark-correction)));
        --deep-blue-90: hsl(240, 20%, calc(100% - 90% + var(--dark-correction)));
        --deep-blue-95: hsl(240, 20%, calc(100% - 95% + var(--dark-correction)));
        --deep-blue-98: hsl(240, 20%, calc(100% - 98% + var(--dark-correction)));
        --deep-blue-99: hsl(240, 20%, calc(100% - 99% + var(--dark-correction)));

        /* pink for dark mode */
        --pink-20: hsl(320, 100%, calc(100% - 20% + 20%));
        --pink-60: hsl(320, 100%, calc(100% - 60% + 20%));
        --pink-80: hsl(320, 100%, calc(100% - 80% + 20%));
        --pink-90: hsl(320, 100%, calc(100% - 90% + 10%));
        --pink-95: hsl(320, 100%, calc(100% - 95% + 10%));
        --pink-98: hsl(320, 100%, calc(100% - 98% + 10%));
        --pink-99: hsl(320, 100%, calc(100% - 99% + 10%));
    }
}