.key{
    /* width: 105px; */
    padding: 0 16px;
    height: 40px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
}
.buttonKey{
    background-color: var(--pink-95);
    color: var(--pink-80);
}
.buttonKey:hover{
    box-shadow: var(--shadow-around-pink-80);
}
.buttonKey:active {
    background-color: var(--pink-80);
    color: var(--pink-95);
  }
.emptyBtn{
    background-color: var(--deep-blue-95);
    color:  var(--deep-blue-20);
}
.emptyBtn:hover{
    box-shadow:var(--shadow-around-deep-blue-20)
}
.emptyBtn:active {
    background-color: var(--deep-blue-20);
    color: var(--deep-blue-95);
}
