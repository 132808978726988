* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    border-radius: 0px;
    text-decoration: none;
    list-style: none;
    text-indent: 0px;
    background-color: transparent;
    color: var(--deep-blue-20);
    font-family: "Quicksand", sans-serif;
}