.key-input {
    height: 48px;
    box-shadow: var(--shadow-around-deep-blue-20);
    font-weight: 500;
    padding: 0 16px;
    border-radius: 8px;
}

.small-input {
    width: auto;
}

.big-input {
    width: 200px;
}