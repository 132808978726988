.menu {
    box-shadow: var(--shadow-bottom-deep-blue-95);
    height: 64px;
    color: var(--deep-blue-60);
    font-weight: 500;
    width: 100%;
    display: flex;
    text-align: left;
    margin: 0;
    text-decoration: none;
    gap: 8px;
    align-items: center;
    padding-left: 25px;
}

.menu:hover {
    background-color: var(--deep-blue-95);
}

.menu.active {
    color: var(--deep-blue-20);
    background-color: var(--deep-blue-95);
    box-shadow: var(--shadow-bottom-deep-blue-80);
}

.menu>.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
    fill: var(--deep-blue-60);
    stroke: var(--deep-blue-60);
}

.menu>.icon-active {
    display: none;
    fill: var(--deep-blue-20);
    stroke: var(--deep-blue-20);
}

.menu.active>.icon {
    display: none;
}

.menu.active>.icon-active {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
}

.menu.collapse {
    display: flex;
    justify-content: center;
    padding: 0;
}