.prime-table {
    width: 335px;
    height: 48px;
    box-shadow: var(--shadow-bottom-deep-blue-95);
    text-align: left;
    display: inline-flex;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;
    overflow-x: hidden;

    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.prime-table:hover {
    background-color: var(--deep-blue-95);
}

.prime-table>.label {
    text-wrap: nowrap;
}

.prime-table>.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
    fill: var(--deep-blue-20);
    stroke: var(--deep-blue-20);
}

.prime-table.select {
    background-color: var(--deep-blue-95);
    box-shadow: var(--shadow-bottom-deep-blue-80);
}

.prime-table.select:hover {
    background-color: var(--deep-blue-90);
}