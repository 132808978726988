.keys-container {
    background-color: var(--deep-blue-98);
}

.keys-container>.top-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid var(--deep-blue-95);
    height: 65px;
    background-color: var(--deep-blue-98);
    gap: 8px;
    padding-left: 16px;
}

.keys-container>.keys-body>div {
    margin: 16px;
    display: flex;
    padding: 16px;
    gap: 8px;
    background-color: white;
    border-radius: 8px;
    width: 95%;
    height: 800px;
    overflow: scroll;
}

.keys-container>.keys-body>div>div {
    height: 60px;
    width: 113px;
    display: inherit;
}