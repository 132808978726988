.iconMenu{
    width: 62px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-bottom-deep-blue-95);
}
.iconMenu>img{
    width: 24px;
    height: 27px;
}